<template>
	<div>
		<transition name="dialog-anim">
			<div v-if="active" class="side-dialog menu active" @click.stop>
				<Card
					name="Your Habits"
					class="heading text mt-2"
					icon="chart-line"
					:fnClick="() => (showHabits = !showHabits)"
					:actions="[
						{
							fnClick: () => openCreateHabit(),
							icon: 'plus',
						},
						{
							fnClick: () => (showHabits = !showHabits),
							icon: showHabits ? 'angle-down' : 'angle-right',
							badge: !showHabits && habits.list.length
						},
					]"
				/>

				<transition>
					<div class="indent" v-show="showHabits">
						<Card
							v-for="(item, i) in habits.list"
							:key="`habit_${i}`"
							:name="item.name"
							class="text light boxed"
							:fnClick="() => openHabit(item.refId)"
						/>
					</div>
				</transition>
			</div>
		</transition>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { HABITS } from "../../data/dialog-keys";
import Card from "../Card";

export default {
	components: { Card },
	data: () => ({
		showHabits: true,
	}),
	computed: {
		...mapGetters({
			dialog: "dialog",
			habits: "habits/data",
		}),
		active() {
			return this.dialog.current == HABITS;
		},
	},
	methods: {
		...mapActions({
			popDialog: "popDialog",
			openDesigner: "habits/openDesigner",
			save: "habits/save"
		}),

		close() {
			this.$emit("close");
		},

		async openHabit(refId) {
			const t = this;
			(await this.openDesigner(this.habits.get(refId)))
				.onSave(() => {
					t.popDialog();
				});
		},

		async openCreateHabit() {
			const t = this;
			(await this.openDesigner())
				.onSave(() => {
					t.popDialog();
				});
		}
	},
};
</script>
