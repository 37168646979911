<template>
	<div>
		<transition name="dialog-anim">
			<div v-show="active && params" class="dialog active" @click.stop>
				<div class="dialog-heading" v-text="heading"></div>

				<div class="dialog-body">
					<TextInput label="Tag Name" v-model="params.name" />

					<Card
						name="Schedules"
						class="heading"
						:state="
							showSchedules ? '' : `(${params.schedules.length})`
						"
						:actions="[
							{
								fnClick: () => openAddSchedule(),
								icon: 'plus',
							},
							{
								fnClick: () => (showSchedules = !showSchedules),
								icon: showSchedules
									? 'angle-down'
									: 'angle-right',
							},
						]"
					/>

					<div v-if="showSchedules" class="indent">
						<Card
							v-for="(schedule, i) in params.schedules"
							:key="`action-schedule_${i}`"
							:fnClick="() => openUpdateSchedule(i)"
							:name="schedule.name"
							class="card-h elevation-1 light mt-2"
							:state="schedule.desc"
							:actions="[
								{
									fnClick: () => removeSchedule(i),
									icon: 'trash',
								},
							]"
						/>
                        <Card
                            v-if="params.schedules.length == 0"
                            name="No Schedules"
                            class="active light sm"
                            theme="disabled"
                            />
					</div>
					</div>

				<div class="dialog-actions">
					<Card name="Save" :fnClick="save" icon="save" class="button" />
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Card from "../Card";
import { TextInput } from "../inputs/index";
import { TAG } from "../../data/dialog-keys";
import { clone } from "../../helpers/objects";

const defaultParams = {
	refId: null,
	name: "",
	schedules: [],
};

export default {
	components: { Card, TextInput },
	data: () => ({
		params: clone(defaultParams),
		exists: false,
		showSchedules: true,
	}),
	computed: {
		...mapGetters({
			dialog: "dialog",
			designer: "tags/designer",
		}),
		active() {
			return this.dialog.current == TAG;
		},
		heading() {
			return this.exists ? "Edit Tag" : "New Tag";
		}
	},
	methods: {
		...mapActions({
			popDialog: "popDialog",
            openSchedule: "openSchedule",
            saveData: "tags/save"
		}),
		save() {
			this.saveData(this.params);
		},

		async openAddSchedule() {
			const t = this;
			const schedule = await this.openSchedule();

			schedule.onSave((data) => {
				t.params.schedules.push(data);
				t.showSchedules = true;
				t.popDialog();
			});
		},
		async openUpdateSchedule(index) {
			const t = this;
			const schedule = await this.openSchedule(
				this.params.schedules[index]
			);

			schedule.onSave((data) => {
				t.params.schedules[index] = data;
				t.showSchedules = true;
				t.popDialog();
			});
		},
		removeSchedule(index) {
			this.params.schedules.splice(index, 1);
		},
	},

	created() {
		this.unsubscribe = this.$store.subscribe((mutation, state) => {
			if (mutation.type === "tags/setDesignerContext") {
				this.params = clone({
					...defaultParams,
					...state.tags.designerData,
				});

                this.exists = this.params.refId != null;
                this.showSchedules = this.params.schedules.length > 0;
			}
		});
	},
	beforeDestroy() {
		this.unsubscribe();
	},
};
</script>
