<template>
    <div>
        <SideMenu dialogKey="calendar">
            <Calendar @select="select" />
        </SideMenu>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SideMenu from "./SideMenu";
import Calendar from "../Calendar";

export default {
    components: { SideMenu, Calendar },
    computed: {
        ...mapGetters(["calendar"])
    },
    methods: {
        ...mapActions(["popDialog"]),
        select(date) {
            this.popDialog();
            this.$emit("select", date);
            this.calendar.callbacks.invokeSelect(date);
        }
    }
}
</script>