var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',{attrs:{"name":"dialog-anim"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active && _vm.params),expression:"active && params"}],staticClass:"dialog active",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"dialog-heading",domProps:{"textContent":_vm._s(_vm.heading)}}),_c('div',{staticClass:"dialog-body"},[_c('TextInput',{attrs:{"label":"Habit Name"},model:{value:(_vm.params.name),callback:function ($$v) {_vm.$set(_vm.params, "name", $$v)},expression:"params.name"}}),_c('Card',{attrs:{"name":"Type","state":_vm.params.positive ? 'Good' : 'Bad',"theme":_vm.params.positive ? 'good' : 'bad',"actions":[
							{
								fnClick: function () { return (_vm.params.positive = true); },
								icon: 'angel',
								active: _vm.params.positive,
							},
							{
								fnClick: function () { return (_vm.params.positive = false); },
								icon: 'skull',
								active: !_vm.params.positive,
							} ]}}),(_vm.params.positive)?[_c('p',[_vm._v(" A good habit will "),_c('strong',{staticClass:"text-bad"},[_vm._v("lose health over time")]),_vm._v(". Log Tasks to help it recover. ")])]:_vm._e(),(!_vm.params.positive)?[_c('p',[_vm._v(" A Bad habit will "),_c('strong',{staticClass:"text-bad"},[_vm._v("regain health over time")]),_vm._v(". Log Tasks to damage it. ")])]:_vm._e(),(_vm.params.positive)?_c('Card',{staticClass:"mt-3",attrs:{"actions":[
                            { icon: 'skull', badge: '-1 H', badgeTheme: 'bad' },
                            { icon: 'award', badge: '+1 H', badgeTheme: 'good' },
                            { icon: 'medal', badge: '+2 H', badgeTheme: 'good' },
                            { icon: 'trophy', badge: '+3 H', badgeTheme: 'good' } ]}}):_c('Card',{staticClass:"mt-3",attrs:{"actions":[
                            { icon: 'skull', badge: '+1 H', badgeTheme: 'bad' },
                            { icon: 'award', badge: '-1 H', badgeTheme: 'good' },
                            { icon: 'medal', badge: '-2 H', badgeTheme: 'good' },
                            { icon: 'trophy', badge: '-3 H', badgeTheme: 'good' } ]}}),_c('div',{staticClass:"v-spacer"}),_c('TextInput',{staticClass:"mb-1",attrs:{"label":"Health","type":"number"},model:{value:(_vm.params.health),callback:function ($$v) {_vm.$set(_vm.params, "health", $$v)},expression:"params.health"}}),_c('TextInput',{staticClass:"mb-1",attrs:{"label":((_vm.params.positive ? 'Damage' : 'Recovery') + " Rate"),"type":"number"},model:{value:(_vm.params.changeRate),callback:function ($$v) {_vm.$set(_vm.params, "changeRate", $$v)},expression:"params.changeRate"}}),_c('Card',{attrs:{"actions":[
							{
								fnClick: function () { return (_vm.params.changeRateFrequencyIndex = 0); },
								text: 'Day',
								active: _vm.params.changeRateFrequencyIndex == 0,
							},
							{
								fnClick: function () { return (_vm.params.changeRateFrequencyIndex = 1); },
								text: 'Week',
								active: _vm.params.changeRateFrequencyIndex == 1,
							},
							{
								fnClick: function () { return (_vm.params.changeRateFrequencyIndex = 2); },
								text: 'Month',
								active: _vm.params.changeRateFrequencyIndex == 2,
							} ]}}),_c('div',{staticClass:"v-spacer"}),_c('Card',{staticClass:"light active sm",attrs:{"name":_vm.description,"icon":"info-circle","theme":"info"}}),_c('div',{staticClass:"v-spacer"}),_c('Card',{staticClass:"heading",attrs:{"name":"Schedules","state":_vm.showSchedules ? '' : ("(" + (_vm.params.schedules.length) + ")"),"actions":[
							{
								fnClick: function () { return _vm.openAddSchedule(); },
								icon: 'plus',
							},
							{
								fnClick: function () { return (_vm.showSchedules = !_vm.showSchedules); },
								icon: _vm.showSchedules
									? 'angle-down'
									: 'angle-right',
							} ]}}),(_vm.showSchedules)?_c('div',{staticClass:"indent"},[_vm._l((_vm.params.schedules),function(schedule,i){return _c('Card',{key:("action-schedule_" + i),staticClass:"card-h elevation-1 light mt-2",attrs:{"fnClick":function () { return _vm.openUpdateSchedule(i); },"name":schedule.name,"state":schedule.desc,"actions":[
								{
									fnClick: function () { return _vm.removeSchedule(i); },
									icon: 'trash',
								} ]}})}),(_vm.params.schedules.length == 0)?_c('Card',{staticClass:"active light sm",attrs:{"name":"No Schedules","theme":"disabled"}}):_vm._e()],2):_vm._e(),_c('div',{staticClass:"v-spacer"}),(_vm.exists)?_c('Card',{staticClass:"light sm",attrs:{"name":_vm.params.alive ? 'Kill' : 'Ressurect',"actions":[
							{
								fnClick: function () { return (_vm.params.alive = !_vm.params.alive); },
								icon: _vm.params.alive
									? 'skull-crossbones'
									: 'hand-holding-seedling',
								active: _vm.params.alive,
							} ]}}):_vm._e(),(_vm.exists)?_c('Card',{staticClass:"light sm",attrs:{"name":_vm.params.paused ? 'Play' : 'Pause',"actions":[
							{
								fnClick: function () { return (_vm.params.paused = !_vm.params.paused); },
								icon: _vm.params.paused ? 'play' : 'pause',
								active: _vm.params.paused,
							} ]}}):_vm._e()],2),_c('div',{staticClass:"dialog-actions"},[_c('Card',{staticClass:"button",attrs:{"name":"Save","fnClick":_vm.save,"icon":"save"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }