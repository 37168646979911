<template>
	<div>
		<transition name="dialog-anim">
			<div v-show="active && params" class="dialog active" @click.stop>
				<div class="dialog-heading" v-text="heading"></div>

				<div class="dialog-body">
					<TextInput label="Habit Name" v-model="params.name" />

					<Card
						class=""
						name="Type"
						:state="params.positive ? 'Good' : 'Bad'"
						:theme="params.positive ? 'good' : 'bad'"
						:actions="[
							{
								fnClick: () => (params.positive = true),
								icon: 'angel',
								active: params.positive,
							},
							{
								fnClick: () => (params.positive = false),
								icon: 'skull',
								active: !params.positive,
							},
						]"
					/>

					<template v-if="params.positive">
						<p>
							A good habit will
							<strong class="text-bad"
								>lose health over time</strong
							>. Log Tasks to help it recover.
						</p>
					</template>
					<template v-if="!params.positive">
						<p>
							A Bad habit will
							<strong class="text-bad"
								>regain health over time</strong
							>. Log Tasks to damage it.
						</p>
					</template>

                    <Card
                        v-if="params.positive"
                        class="mt-3"
                        :actions="[
                            { icon: 'skull', badge: '-1 H', badgeTheme: 'bad' },
                            { icon: 'award', badge: '+1 H', badgeTheme: 'good' },
                            { icon: 'medal', badge: '+2 H', badgeTheme: 'good' },
                            { icon: 'trophy', badge: '+3 H', badgeTheme: 'good' },
                        ]"
                    />
                    <Card
                        v-else
                        class="mt-3"    
                        :actions="[
                            { icon: 'skull', badge: '+1 H', badgeTheme: 'bad' },
                            { icon: 'award', badge: '-1 H', badgeTheme: 'good' },
                            { icon: 'medal', badge: '-2 H', badgeTheme: 'good' },
                            { icon: 'trophy', badge: '-3 H', badgeTheme: 'good' },
                        ]"
                    />

					<div class="v-spacer"></div>

					<TextInput
						label="Health"
						v-model="params.health"
						type="number"
						class="mb-1"
					/>

					<TextInput
						:label="
							`${params.positive ? 'Damage' : 'Recovery'} Rate`
						"
						v-model="params.changeRate"
						type="number"
						class="mb-1"
					/>

					<Card
						:actions="[
							{
								fnClick: () => (params.changeRateFrequencyIndex = 0),
								text: 'Day',
								active: params.changeRateFrequencyIndex == 0,
							},
							{
								fnClick: () => (params.changeRateFrequencyIndex = 1),
								text: 'Week',
								active: params.changeRateFrequencyIndex == 1,
							},
							{
								fnClick: () => (params.changeRateFrequencyIndex = 2),
								text: 'Month',
								active: params.changeRateFrequencyIndex == 2,
							},
						]"
					/>

					<div class="v-spacer"></div>

					<Card
						:name="description"
						icon="info-circle"
						class="light active sm"
						theme="info"
					/>

					<div class="v-spacer"></div>

					<Card
						name="Schedules"
						class="heading"
						:state="
							showSchedules ? '' : `(${params.schedules.length})`
						"
						:actions="[
							{
								fnClick: () => openAddSchedule(),
								icon: 'plus',
							},
							{
								fnClick: () => (showSchedules = !showSchedules),
								icon: showSchedules
									? 'angle-down'
									: 'angle-right',
							},
						]"
					/>

					<div v-if="showSchedules" class="indent">
						<Card
							v-for="(schedule, i) in params.schedules"
							:key="`action-schedule_${i}`"
							:fnClick="() => openUpdateSchedule(i)"
							:name="schedule.name"
							class="card-h elevation-1 light mt-2"
							:state="schedule.desc"
							:actions="[
								{
									fnClick: () => removeSchedule(i),
									icon: 'trash',
								},
							]"
						/>
                        <Card
                            v-if="params.schedules.length == 0"
                            name="No Schedules"
                            class="active light sm"
                            theme="disabled"
                            />
					</div>

					<div class="v-spacer"></div>

					<Card
						v-if="exists"
						:name="params.alive ? 'Kill' : 'Ressurect'"
						class="light sm"
						:actions="[
							{
								fnClick: () => (params.alive = !params.alive),
								icon: params.alive
									? 'skull-crossbones'
									: 'hand-holding-seedling',
								active: params.alive,
							},
						]"
					/>

					<Card
						v-if="exists"
						:name="params.paused ? 'Play' : 'Pause'"
						class="light sm"
						:actions="[
							{
								fnClick: () => (params.paused = !params.paused),
								icon: params.paused ? 'play' : 'pause',
								active: params.paused,
							},
						]"
					/>
				</div>

				<div class="dialog-actions">
					<Card name="Save" :fnClick="save" icon="save" class="button" />
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Card from "../Card";
import { TextInput } from "../inputs/index";
import { HABIT } from "../../data/dialog-keys";
import { clone } from "../../helpers/objects";

const defaultParams = {
	refId: null,
	name: "",
	positive: true,
	alive: true,
	paused: false,
	health: 10,
	changeRate: 1,
	changeRateFrequencyIndex: 0,
	schedules: [],
};

const frequencies = [
	{
		name: "Day",
	},
	{
		name: "Week",
	},
	{
		name: "Month",
	},
];

export default {
	components: { Card, TextInput },
	data: () => ({
		params: clone(defaultParams),
		exists: false,
		showSchedules: true,
	}),
	computed: {
		...mapGetters({
			dialog: "dialog",
			designer: "habits/designer",
		}),
		active() {
			return this.dialog.current == HABIT;
		},
		heading() {
			return this.exists ? "Edit Habit" : "New Habit";
		},
		description() {
			return `${this.params.positive ? "-" : "+"}${
				this.params.changeRate
			} Health per ${frequencies[this.params.changeRateFrequencyIndex].name}`;
		},
		frequencies: () => frequencies,
	},
	methods: {
		...mapActions({
			popDialog: "popDialog",
            openSchedule: "openSchedule",
            saveData: "habits/save"
		}),
		save() {
			this.saveData(this.params);
		},

		async openAddSchedule() {
			const t = this;
			const schedule = await this.openSchedule();

			schedule.onSave((data) => {
				t.params.schedules.push(data);
				t.showSchedules = true;
				t.popDialog();
			});
		},
		async openUpdateSchedule(index) {
			const t = this;
			const schedule = await this.openSchedule(
				this.params.schedules[index]
			);

			schedule.onSave((data) => {
				t.params.schedules[index] = data;
				t.showSchedules = true;
				t.popDialog();
			});
		},
		removeSchedule(index) {
			this.params.schedules.splice(index, 1);
		},
	},

	created() {
		this.unsubscribe = this.$store.subscribe((mutation, state) => {
			if (mutation.type === "habits/setDesignerContext") {
				this.params = clone({
					...defaultParams,
					...state.habits.designerData,
				});

                this.exists = this.params.refId != null;
                this.showSchedules = this.params.schedules.length > 0;
			}
		});
	},
	beforeDestroy() {
		this.unsubscribe();
	},
};
</script>
