var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',{attrs:{"name":"dialog-anim"}},[(_vm.active)?_c('div',{staticClass:"side-dialog menu active",on:{"click":function($event){$event.stopPropagation();}}},[_c('Card',{staticClass:"heading text mt-2",attrs:{"name":"Your Habits","icon":"chart-line","fnClick":function () { return (_vm.showHabits = !_vm.showHabits); },"actions":[
					{
						fnClick: function () { return _vm.openCreateHabit(); },
						icon: 'plus',
					},
					{
						fnClick: function () { return (_vm.showHabits = !_vm.showHabits); },
						icon: _vm.showHabits ? 'angle-down' : 'angle-right',
						badge: !_vm.showHabits && _vm.habits.list.length
					} ]}}),_c('transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHabits),expression:"showHabits"}],staticClass:"indent"},_vm._l((_vm.habits.list),function(item,i){return _c('Card',{key:("habit_" + i),staticClass:"text light boxed",attrs:{"name":item.name,"fnClick":function () { return _vm.openHabit(item.refId); }}})}),1)])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }