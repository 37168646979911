<template>
	<SideMenu dialogKey="schedule">
		<div class="dialog-heading">{{ exists ? 'Update' : 'Add' }} Schedule</div>
		<div class="dialog-body">
			<div>
				<Card
					name="Start"
					class="light"
					:state="fromText || 'Not set'"
					:actions="[
						{
							fnClick: () => openSetStart(),
							icon: 'calendar-alt',
						},
						{
							fnClick: () => removeStart(),
							icon: 'times',
							show: params.from != null,
						},
					]"
				/>
				<Card
					v-if="params.from"
					name="Finish"
					class="light"
					:state="params.to ? toText : 'Never'"
					:actions="[
						{
							fnClick: () => openSetEnd(),
							icon: 'calendar-alt',
						},
						{
							fnClick: () => removeEnd(),
							icon: 'times',
							show: params.to != null,
						},
					]"
				/>

				<div v-if="params.from">
					<TextInput
						label="Repeat Every"
						type="number"
						v-model="params.frequencyInterval"
						:fnModValue="
							(v) =>
								mod(
									v - 1,
									frequencyRanges[params.frequencyIndex][1]
								) + 1
						"
					/>
					<Card
						name=""
						class="light"
						:actions="[
							{
								text:
									params.frequencyInterval > 1
										? 'Days'
										: 'Day',
								fnClick: () => (params.frequencyIndex = 0),
								active: params.frequencyIndex == 0,
							},
							{
								text:
									params.frequencyInterval > 1
										? 'Weeks'
										: 'Week',
								fnClick: () => (params.frequencyIndex = 1),
								active: params.frequencyIndex == 1,
							},
							{
								text:
									params.frequencyInterval > 1
										? 'Months'
										: 'Month',
								fnClick: () => (params.frequencyIndex = 2),
								active: params.frequencyIndex == 2,
							},
							{
								text:
									params.frequencyInterval > 1
										? 'Years'
										: 'Year',
								fnClick: () => (params.frequencyIndex = 3),
								active: params.frequencyIndex == 3,
							},
						]"
					/>

					<div class="v-spacer"></div>

					<div v-if="params.frequencyIndex == 1">
						<Card
							name="Days"
							class="light"
							:actions="[
								{
									text: 'M',
									fnClick: () => toggleWeekDay(0),
									active: isWeekDaySelected(0),
								},
								{
									text: 'T',
									fnClick: () => toggleWeekDay(1),
									active: isWeekDaySelected(1),
								},
								{
									text: 'W',
									fnClick: () => toggleWeekDay(2),
									active: isWeekDaySelected(2),
								},
								{
									text: 'T',
									fnClick: () => toggleWeekDay(3),
									active: isWeekDaySelected(3),
								},
								{
									text: 'F',
									fnClick: () => toggleWeekDay(4),
									active: isWeekDaySelected(4),
								},
								{
									text: 'S',
									fnClick: () => toggleWeekDay(5),
									active: isWeekDaySelected(5),
								},
								{
									text: 'S',
									fnClick: () => toggleWeekDay(6),
									active: isWeekDaySelected(6),
								},
							]"
						/>
					</div>

					<div class="v-spacer"></div>
					<Card :name="description" theme="info" icon="info-circle" class="border light" />
				</div>
			</div>
		</div>
		<div class="dialog-actions">
			<a class="btn text" href="#" @click.prevent="save">{{ exists ? 'Apply' : 'Add' }}</a>
		</div>
	</SideMenu>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Card from "./Card";
import SideMenu from "./menus/SideMenu";
import { TextInput } from "./inputs/index";
import { clone } from "../helpers/objects";
import { mod } from "../helpers/math";
import { ordinalSuffix } from "../helpers/numbers";
import dayjs from "dayjs";

const defaultParams = {
	from: null,
	to: null,
	frequencyIndex: 0,
	frequencyInterval: 1,
	frequencyMeta: {
		weekDays: null,
	},
};

export default {
	components: { Card, TextInput, SideMenu },
	data: () => ({
		exists: false,
		frequencyRanges: [
			[1, 365],
			[1, 52],
			[1, 12],
			[1, 10],
		],
		weekDayNames: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
		toText: null,
		fromText: null,
		params: clone(defaultParams),
	}),
	computed: {
		...mapGetters(["schedule"]),
		description() {
			const selectedWeekDays = this.params.frequencyMeta.weekDays;
			let days = [];
			let plural = this.params.frequencyInterval > 1;
			let ending = ".";
			if (this.params.to) ending = ` until ${this.toText}.`;

			switch (this.params.frequencyIndex) {
				case 0:
					return `Repeats every ${
						plural ? this.params.frequencyInterval + " days" : "day"
					} from ${this.fromText}${ending}`;
				case 1:
					if (selectedWeekDays) {
						for (var i = 0; i < selectedWeekDays.length; i++) {
							days.push(this.weekDayNames[selectedWeekDays[i]]);
						}

						return `Repeats every ${
							plural
								? ordinalSuffix(this.params.frequencyInterval) +
								" "
								: ""
						}${days.reduce((p, c) => p + ", " + c)} from ${
							this.fromText
						}${ending}`;
					}
					return `Repeats every ${
						plural
							? this.params.frequencyInterval + " weeks"
							: "week"
					} from ${this.fromText}${ending}`;
				case 2:
					return `Repeats every ${
						plural
							? this.params.frequencyInterval + " months"
							: "month"
					} from ${this.fromText}${ending}`;
				case 3:
					return `Repeats every ${
						plural
							? this.params.frequencyInterval + " years"
							: "year"
					} from ${this.fromText}${ending}`;
			}

			return "Not set";
		},
	},
	methods: {
		...mapActions(["openCalendar"]),
		async openSetStart() {
			const t = this;
			const calendar = await this.openCalendar();

			calendar.onSelect((date) => {
				t.params.from = date.unix;
				t.fromText = date.data.format("DD MMM YYYY");
			});
		},
		removeStart() {
			this.params.from = null;
			this.fromText = null;
		},
		async openSetEnd() {
			const t = this;
			const calendar = await this.openCalendar();

			calendar.onSelect((date) => {
				t.params.to = date.unix;
				t.toText = date.data.format("DD MMM YYYY");
			});
		},
		removeEnd() {
			this.params.to = null;
			this.toText = null;
		},
		mod,
		save() {
			if (!this.params.from)
			{
				return;
			}

			this.params.name = this.fromText;
			if (this.params.to)
				this.params.name += " - " + this.toText;
			this.params.desc = this.description;
			this.schedule.callbacks.invokeSave(this.params);
		},
		isWeekDaySelected(index) {
			return (
				this.params.frequencyMeta.weekDays &&
				this.params.frequencyMeta.weekDays.includes(index)
			);
		},
		toggleWeekDay(index) {
			if (!this.params.frequencyMeta.weekDays) {
				this.params.frequencyMeta.weekDays = [index];
				return;
			}

			var idx = this.params.frequencyMeta.weekDays.findIndex(
				(x) => x == index
			);
			if (idx < 0) {
				this.params.frequencyMeta.weekDays.push(index);
				this.params.frequencyMeta.weekDays.sort();
			} else {
				this.params.frequencyMeta.weekDays.splice(idx, 1);
				if (this.params.frequencyMeta.weekDays.length == 0)
					this.params.frequencyMeta.weekDays = null;
			}
		},
	},

	created() {
		this.unsubscribe = this.$store.subscribe((mutation, state) => {
			if (mutation.type === "setScheduleContext") {
				this.params = clone({
					...defaultParams,
					...state.scheduleParams,
				});

				this.exists = this.params.from != null;

				if (this.exists)
					this.fromText = dayjs.unix(this.params.from).format(
						"DD MMM YYYY"
					);
				else this.fromText = null;

				if (this.params.to)
					this.toText = dayjs.unix(this.params.to).format("DD MMM YYYY");
				else this.toText = null;
			}
		});
	},
	beforeDestroy() {
		this.unsubscribe();
	},
};
</script>
