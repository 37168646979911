<template>
	<div>
		<transition name="dialog-anim">
			<div v-if="active" class="side-dialog menu active" @click.stop>
				<div class="menu-heading">
					<i class="fad fa-fw fa-tasks"></i>Your Tasks
				</div>

				<div class="dialog-body">
				<Card
					name="Add From Code"
					class="button"
					icon="terminal"
					:fnClick="() => openCreateCode()"
				/>
				</div>

				<Card
					name="Tags"
					class="heading text"
					icon="tag"
					:fnClick="() => (showTags = !showTags)"
					:actions="[
						{
							fnClick: () => openCreateTag(),
							icon: 'plus',
						},
						{
							fnClick: () => (showTags = !showTags),
							icon: showTags ? 'angle-down' : 'angle-right',
							badge: !showTags && tags.list.length
						},
					]"
				/>

				<div v-show="showTags" class="indent">
					<Card
						v-for="(item, i) in tags.list"
						:key="`task-tag_${i}`"
						:name="item.name"
						class="text light boxed"
						:fnClick="() => openTag(item.refId)"
					/>
				</div>

				<div class="v-spacer"></div>

				<Card
					name="Tasks (Single Action)"
					class="heading text"
					icon="credit-card-blank"
					:fnClick="() => (showActions = !showActions)"
					:actions="[
						{
							fnClick: () => openCreateAction(),
							icon: 'plus',
						},
						{
							fnClick: () => (showActions = !showActions),
							icon: showActions ? 'angle-down' : 'angle-right',
							badge: !showActions && actions.length
						},
					]"
				/>

				<div v-show="showActions" class="indent">
					<Card
						v-for="(task, i) in actions"
						:key="`task-action_${i}`"
						:name="task.name"
						class="text light boxed"
						:icon="getPositiveIcon(task.positive)"
						:theme="task.positive ? 'good' : 'bad'"
						:fnClick="() => openAction(task.refId)"
						:actions="[
							{
								fnClick: () => togglePinned(task._id),
								icon: 'thumbtack',
								active: isPinned(task._id),
							},
							{
								fnClick: () => createLog(task._id),
								icon: 'file-plus',
							},
						]"
					/>
				</div>

				<div class="v-spacer"></div>

				<Card
					name="Tasks (Sequence)"
					class="heading text"
					icon="bars"
					:fnClick="() => (showSequences = !showSequences)"
					:actions="[
						{
							fnClick: () => openCreateSequence(),
							icon: 'plus',
						},
						{
							fnClick: () => (showSequences = !showSequences),
							icon: showSequences ? 'angle-down' : 'angle-right',
							badge: !showSequences && sequences.length
						},
					]"
				/>

				<div v-show="showSequences" class="indent">
					<Card
						v-for="(task, i) in sequences"
						:key="`task-sequence_${i}`"
						:name="task.name"
						class="text light boxed"
						:icon="getPositiveIcon(task.positive)"
						:theme="task.positive ? 'good' : 'bad'"
						:fnClick="() => openSequence(task.refId)"
						:actions="[
							{
								fnClick: () => togglePinned(task._id),
								icon: 'thumbtack',
								active: isPinned(task._id),
							},
							{
								fnClick: () => createLog(task._id),
								icon: 'file-plus',
							},
						]"
					/>
				</div>
			</div>
		</transition>
		<SideMenu :dialogKey="dialogKeys.TASKS_FROMCODE">
			<div class="dialog-heading">Add Task from Code</div>
			<div class="dialog-body">
				<TextInput
					label="Code"
					:fnModValue="(v) => v.toUpperCase()"
					v-model="taskCode"
				/>
			</div>
			<div class="dialog-actions">
				<button @click="addTaskFromCode">Add</button>
			</div>
		</SideMenu>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
	TASKS,
	TASKS_FROMCODE,
	ACTION,
	SEQUENCE,
} from "../../data/dialog-keys";
import SideMenu from "./SideMenu";
import TextInput from "../inputs/TextInput";
import Card from "../Card";

export default {
	components: { SideMenu, TextInput, Card },
	props: {
		pinned: {
			type: Array,
			default: () => [],
		},
	},
	data: () => ({
		taskCode: "",

		showTags: true,
		showActions: true,
		showSequences: true,
	}),
	computed: {
		...mapGetters({
			dialog: "dialog",
			tasks: "tasks/data",
			tags: "tags/data"
		}),
		dialogKeys: () => ({ TASKS_FROMCODE }),
		active() {
			return this.dialog.current == TASKS;
		},
		actions() {
			return this.tasks.list.filter(x => x.type == "action");
		},
		sequences() { 
			return this.tasks.list.filter(x => x.type == "sequence");
		}
	},
	methods: {
		...mapActions({
			popDialog: "popDialog",
			pushDialog: "pushDialog",
			setActionContext: "tasks/setActionContext",
			setSequenceContext: "tasks/setSequenceContext",
			setSequenceContextCreate: "tasks/setSequenceContextCreate",
			getTaskFromCode: "tasks/getTaskFromCode",
			openTagDesigner: "tags/openDesigner"
		}),

		openCreateCode() {
			this.pushDialog(TASKS_FROMCODE);
		},
		async addTaskFromCode() {
			const res = await this.getTaskFromCode(this.taskCode);
			const task = res.data.data.task;
			switch (task.type) {
				case "action":
					this.setActionContext(task);
					this.pushDialog(ACTION);
					return;

				case "sequence":
					this.setSequenceContext(task);
					this.pushDialog(SEQUENCE);
					return;
			}
		},

		openCreateAction() {
			this.setActionContext({});
			this.pushDialog(ACTION);
		},
		openAction(refId) {
			const action = this.tasks.getFromRefId(refId);
			this.setActionContext(action);
			this.pushDialog(ACTION);
		},

		openCreateSequence() {
			this.setSequenceContextCreate();
			this.pushDialog(SEQUENCE);
		},
		openSequence(refId) {
			this.setSequenceContext(this.tasks.getFromRefId(refId));
			this.pushDialog(SEQUENCE);
		},

		togglePinned(id) {
			this.$emit("togglePinned", id);
		},
		isPinned(id) {
			return this.pinned.includes(id);
		},
		createLog(id) {
			this.$emit("createLog", id);
		},
		getIcon(type) {
			switch (type) {
				case "action":
					return "fa-credit-card-blank";
				case "sequence":
					return "fa-bars";
				case "series":
					return "fa-line-columns";
			}

			return "fa-dot";
		},
		getEffortIcon(effort) {
			if (effort == null || effort == undefined) effort = 1;

			switch (effort) {
				case 1:
					return "fa-smile";
				case 2:
					return "fa-frown-open";
				case 3:
					return "fa-sad-cry";
				default:
					return "fa-meh-blank";
			}
		},
		getPositiveIcon(positive) {
			if (positive == null) positive = true;

			if (positive) return "trophy";
			else return "skull";
		},

		async openTag(refId) {
			const t = this;
			(await this.openTagDesigner(this.tags.get(refId)))
				.onSave(() => {
					t.popDialog();
				});
		},
		async openCreateTag() {
			const t = this;
			(await this.openTagDesigner())
				.onSave(() => {
					t.popDialog();
				});
		}
	},
};
</script>
