<template>
	<div class="page">
		<DialogMask />
		<Calendar
			@open="openDay"
			:context="calendarContext"
			:range-from="calendarFrom"
			:range-to="calendarTo"
			:range-data="calendarRangeData"
			:range-repeat="calendarRangeRepeat"
			@select-from="(d) => setCreateScheduleFrom(d)"
			@select-to="(d) => setCreateScheduleTo(d)"
		/>

		<Card v-if="calendarContext == 'select-from'" name="Select from date" />
		<Card v-if="calendarContext == 'select-to'" name="Select to date" />

		<HabitMenu />
		<TaskMenu />

		<div class="action-bar-container">
			<div class="action-bar-row">
				<div>
					<div class="action-bar">
						<router-link class="btn" to="/board">
							<i class="fas fa-fw fa-arrow-left"></i>
							<label>Logs</label>
						</router-link>
						<a
							href="#"
							:class="[
								'btn',
								{
									active:
										dialog.stack[0] == dialogKeys.HABITS,
								},
							]"
							@click.prevent="() => setDialog(dialogKeys.HABITS)"
						>
							<i class="fad fa-fw fa-chart-line"></i>
							<label>Habits</label>
						</a>

						<a
							href="#"
							:class="[
								'btn',
								{ active: dialog.stack[0] == dialogKeys.TASKS },
							]"
							@click.prevent="() => setDialog(dialogKeys.TASKS)"
						>
							<i class="fad fa-fw fa-tasks"></i>
							<label>Tasks</label>
						</a>
					</div>
				</div>
			</div>
		</div>

		<SideMenu :dialogKey="dialogKeys.PLAN_CREATE">
			<Card name="Create" class="heading text" />
			<Card
				name="Schedule"
				icon="calendar-alt"
				class="light boxed text"
				:fnClick="() => openCreateSchedule()"
			/>
			<Card
				name="Task"
				icon="tasks"
				class="light boxed text"
				:fnClick="() => null"
			/>
			<Card
				name="Habit"
				icon="chart-line"
				class="light boxed text"
				:fnClick="() => null"
			/>
		</SideMenu>

		<SideMenu :dialogKey="dialogKeys.PLAN_DAY">
			<div v-if="day">
				<Card
					:name="day.data.format('ddd DD, MMM YYYY')"
					icon="calendar"
					class="heading text"
				/>
				<Card
					name="Schedule"
					icon="calendar-alt"
					class="text light heading"
					:actions="[{ fnClick: () => openAddPlan(), icon: 'plus' }]"
				/>
				<Card
					v-for="(item, i) in day.items"
					:key="`item_${i}`"
					:name="item.name"
					:icon="item.icon"
					class="text light"
				/>
			</div>
		</SideMenu>

		<SideMenu :dialogKey="dialogKeys.PLAN_SCHEDULE">
			<Card name="Add a Plan" class="heading text" />

			<div class="dialog-body">
				<TextInput label="Name" id="ScheduleName" />
				<Card
					name="Repeats"
					class="text"
					:actions="[
						{
							icon: plan.repeats ? 'check-square' : 'square',
							fnClick: () => (plan.repeats = !plan.repeats),
						},
					]"
				/>
				<div class="v-spacer"></div>
				<Card
					name="Days"
					:actions="[{ icon: 'plus', fnClick: () => planAddDay() }]"
				/>

				<div v-for="(day, i) in plan.days" :key="`day_${i}`">
					<Card
						:key="`day_${i}`"
						:name="getDayName(i)"
						theme="great"
						class="light text sm condensed"
						icon="calendar-alt"
						:actions="[
							{ icon: 'trash', fnClick: () => planRemoveDay(i) },
							{ icon: 'plus', fnClick: () => planDayAddTask(i) },
						]"
					/>
					<Card
						v-for="(item, j) in day"
						:key="`day_${i}-item_${j}`"
						name="Task"
						icon="tasks"
						class="light active fade indent-1 boxed"
						:actions="[
							{
								icon: 'trash',
								fnClick: () => planDayRemoveItem(i, j),
							},
						]"
					/>
				</div>

				<div class="v-spacer"></div>
				<Card name="Notifications" :actions="[{ icon: 'plus' }]" />
				<Card
					name="Reminder"
					icon="exclamation"
					class="light"
					:actions="[{ icon: 'trash' }]"
				/>
			</div>
		</SideMenu>

		<ActionDesigner />
		<SequenceDesigner />
		<HabitDesigner />
		<TagDesigner />
		
		<Schedule />

		<CalendarMenu />
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SideMenu from "../components/menus/SideMenu";
import Calendar from "../components/Calendar";
import Card from "../components/Card";
import DialogMask from "../components/DialogMask";
import { TextInput } from "../components/inputs/index";
import HabitMenu from "../components/menus/HabitMenu";
import TaskMenu from "../components/menus/TaskMenu";
import { ActionDesigner, SequenceDesigner, HabitDesigner, TagDesigner } from "../components/designers/index";
import CalendarMenu from "../components/menus/CalendarMenu";
import Schedule from "../components/Schedule";

import {
	PLAN_DAY,
	PLAN_CREATE,
	PLAN_SCHEDULE,
	TASKS,
	HABITS,
} from "../data/dialog-keys";
import { clone } from "../helpers/objects";

const planDefault = {
	repeats: true,
	days: [[], [], [], [], [], [], []],
	notifications: [],
};

export default {
	components: {
		HabitMenu,
		TaskMenu,
		SideMenu,
		Calendar,
		Card,
		DialogMask,
		TextInput,
		ActionDesigner,
		SequenceDesigner,
		HabitDesigner,
		TagDesigner,
		CalendarMenu,
		Schedule
	},
	data: () => ({
		day: null,
		plan: clone(planDefault),

		calendarContext: "",
		calendarFrom: null,
		calendarTo: null,
		calendarRangeData: null,
		calendarRangeRepeat: true,
	}),
	computed: {
		dialogKeys: () => ({
			PLAN_DAY,
			PLAN_CREATE,
			PLAN_SCHEDULE,
			TASKS,
			HABITS,
		}),
		...mapGetters({
			dialog: "dialog",
			plans: "plans/data",
		}),
	},
	methods: {
		...mapActions({
			clearDialog: "clearDialog",
			setDialog: "setDialog",
			pushDialog: "pushDialog",
			loadAll: "loadAll",
		}),

		openCreate() {
			this.pushDialog(PLAN_CREATE);
		},

		openDay(day) {
			this.day = day;
			this.day.plans = this.plans.getFromDay(day.data.valueOf());
			this.pushDialog(PLAN_DAY);
		},

		openAddPlan() {
			this.plan = clone(planDefault);
			this.pushDialog(PLAN_SCHEDULE);
		},

		openEditPlan() {},

		planAddDay() {
			this.plan.days.push([]);
		},
		planRemoveDay(dayIdx) {
			this.plan.days.splice(dayIdx, 1);
		},

		planDayAddTask(dayIdx) {
			this.plan.days[dayIdx].push({});
		},
		planDayRemoveItem(dayIdx, itemIdx) {
			this.plan.days[dayIdx].splice(itemIdx, 1);
		},

		getDayName(index) {
			if (!this.day) return '';

			return this.day.data.add(index, "day").format("ddd, DD MMM");
		},

		openCreateSchedule() {
			this.calendarContext = "select-from";
			this.calendarFrom = null;
			this.calendarTo = null;
			this.clearDialog();
		},
		setCreateScheduleFrom(d) {
			this.calendarContext = "select-to";
			this.calendarFrom = d.unix();
		},
		setCreateScheduleTo(d) {
			this.calendarContext = "";
			const to = d.unix();
			if (to > this.calendarFrom)
				this.calendarTo = to;
			else {
				this.calendarTo = this.calendarFrom;
				this.calendarFrom = to;
			}
			console.log("from", this.calendarFrom, "to", this.calendarTo);
		},
		closeCreateSchedule() {
			this.calendarContext = "";
		},
	},
	async created() {
		await this.loadAll();
	},
};
</script>
