var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('DialogMask'),_c('Calendar',{attrs:{"context":_vm.calendarContext,"range-from":_vm.calendarFrom,"range-to":_vm.calendarTo,"range-data":_vm.calendarRangeData,"range-repeat":_vm.calendarRangeRepeat},on:{"open":_vm.openDay,"select-from":function (d) { return _vm.setCreateScheduleFrom(d); },"select-to":function (d) { return _vm.setCreateScheduleTo(d); }}}),(_vm.calendarContext == 'select-from')?_c('Card',{attrs:{"name":"Select from date"}}):_vm._e(),(_vm.calendarContext == 'select-to')?_c('Card',{attrs:{"name":"Select to date"}}):_vm._e(),_c('HabitMenu'),_c('TaskMenu'),_c('div',{staticClass:"action-bar-container"},[_c('div',{staticClass:"action-bar-row"},[_c('div',[_c('div',{staticClass:"action-bar"},[_c('router-link',{staticClass:"btn",attrs:{"to":"/board"}},[_c('i',{staticClass:"fas fa-fw fa-arrow-left"}),_c('label',[_vm._v("Logs")])]),_c('a',{class:[
							'btn',
							{
								active:
									_vm.dialog.stack[0] == _vm.dialogKeys.HABITS,
							} ],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return (function () { return _vm.setDialog(_vm.dialogKeys.HABITS); })($event)}}},[_c('i',{staticClass:"fad fa-fw fa-chart-line"}),_c('label',[_vm._v("Habits")])]),_c('a',{class:[
							'btn',
							{ active: _vm.dialog.stack[0] == _vm.dialogKeys.TASKS } ],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return (function () { return _vm.setDialog(_vm.dialogKeys.TASKS); })($event)}}},[_c('i',{staticClass:"fad fa-fw fa-tasks"}),_c('label',[_vm._v("Tasks")])])],1)])])]),_c('SideMenu',{attrs:{"dialogKey":_vm.dialogKeys.PLAN_CREATE}},[_c('Card',{staticClass:"heading text",attrs:{"name":"Create"}}),_c('Card',{staticClass:"light boxed text",attrs:{"name":"Schedule","icon":"calendar-alt","fnClick":function () { return _vm.openCreateSchedule(); }}}),_c('Card',{staticClass:"light boxed text",attrs:{"name":"Task","icon":"tasks","fnClick":function () { return null; }}}),_c('Card',{staticClass:"light boxed text",attrs:{"name":"Habit","icon":"chart-line","fnClick":function () { return null; }}})],1),_c('SideMenu',{attrs:{"dialogKey":_vm.dialogKeys.PLAN_DAY}},[(_vm.day)?_c('div',[_c('Card',{staticClass:"heading text",attrs:{"name":_vm.day.data.format('ddd DD, MMM YYYY'),"icon":"calendar"}}),_c('Card',{staticClass:"text light heading",attrs:{"name":"Schedule","icon":"calendar-alt","actions":[{ fnClick: function () { return _vm.openAddPlan(); }, icon: 'plus' }]}}),_vm._l((_vm.day.items),function(item,i){return _c('Card',{key:("item_" + i),staticClass:"text light",attrs:{"name":item.name,"icon":item.icon}})})],2):_vm._e()]),_c('SideMenu',{attrs:{"dialogKey":_vm.dialogKeys.PLAN_SCHEDULE}},[_c('Card',{staticClass:"heading text",attrs:{"name":"Add a Plan"}}),_c('div',{staticClass:"dialog-body"},[_c('TextInput',{attrs:{"label":"Name","id":"ScheduleName"}}),_c('Card',{staticClass:"text",attrs:{"name":"Repeats","actions":[
					{
						icon: _vm.plan.repeats ? 'check-square' : 'square',
						fnClick: function () { return (_vm.plan.repeats = !_vm.plan.repeats); },
					} ]}}),_c('div',{staticClass:"v-spacer"}),_c('Card',{attrs:{"name":"Days","actions":[{ icon: 'plus', fnClick: function () { return _vm.planAddDay(); } }]}}),_vm._l((_vm.plan.days),function(day,i){return _c('div',{key:("day_" + i)},[_c('Card',{key:("day_" + i),staticClass:"light text sm condensed",attrs:{"name":_vm.getDayName(i),"theme":"great","icon":"calendar-alt","actions":[
						{ icon: 'trash', fnClick: function () { return _vm.planRemoveDay(i); } },
						{ icon: 'plus', fnClick: function () { return _vm.planDayAddTask(i); } } ]}}),_vm._l((day),function(item,j){return _c('Card',{key:("day_" + i + "-item_" + j),staticClass:"light active fade indent-1 boxed",attrs:{"name":"Task","icon":"tasks","actions":[
						{
							icon: 'trash',
							fnClick: function () { return _vm.planDayRemoveItem(i, j); },
						} ]}})})],2)}),_c('div',{staticClass:"v-spacer"}),_c('Card',{attrs:{"name":"Notifications","actions":[{ icon: 'plus' }]}}),_c('Card',{staticClass:"light",attrs:{"name":"Reminder","icon":"exclamation","actions":[{ icon: 'trash' }]}})],2)],1),_c('ActionDesigner'),_c('SequenceDesigner'),_c('HabitDesigner'),_c('TagDesigner'),_c('Schedule'),_c('CalendarMenu')],1)}
var staticRenderFns = []

export { render, staticRenderFns }